<template>
 <div class="content">
   <h1 class="title title--theme title--indent">Статистика: Отчет о реализации</h1>
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <div slot="delegation_name" slot-scope="props" class="table-default__left">
       {{ props.row.delegation_name }}
     </div>
     <template #report_exist="props">
       {{ props.row.report_exist ? 'Да' : 'Нет' }}
     </template>
   </v-client-table>
 </div>
</template>

<script>
import { getRealizationReportStat } from '@/http'

export default {
  name: 'RealizationReportStat',
  data() {
    return {
      table: {
        items: [],
        columns: ['delegation_name', 'report_exist'],
        options: {
          headings: {
            delegation_name: 'Делегация',
            report_exist: 'Сдан',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 1000,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Делегации отсутствуют',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row ${row.report_exist ? 'table-default__row--accept' : 'table-default__row--reject'}`,
        },
      },
      participants_done: false,
      participants_print: false,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      getRealizationReportStat()
        .then(response => {
          this.table.items = response.data
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'realizationreport', params: { delegation_id: row.row.delegation_id } })
    },
  }
}
</script>

